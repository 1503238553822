import React from 'react';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import './Footer.css'; // Ensure you create this CSS file for custom styles

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='contact-section'>
        <a
          href="https://x.com/gaborhollbeck"
          target="_blank"
          rel="noopener noreferrer"
          className='transition-transform ease-in-out transform hover:scale-125'
        >
          <FaXTwitter size="1.5em" className="icon-grey-transparent" />
        </a>
        <div className='contact-item'>
   
          <p>@gaborhollbeck</p>
          <a href="mailto:ghollbeck@ethz.ch">ghollbeck@ethz.ch</a>
        </div>
        <a
          href="https://www.linkedin.com/in/gaborhollbeck/"
          target="_blank"
          rel="noopener noreferrer"
          className='transition-transform ease-in-out transform hover:scale-125'
        >
          <FaLinkedinIn size="1.5em" className="icon-grey-transparent" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
