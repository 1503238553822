import React, { useEffect, useState, useRef } from 'react';
import LandingPage from './components/LandingPage';
import './index.css'; // Adjust the path as necessary
import Footer from './components/Footer';
import DropdownTextBox from './components/DropdownTextBox';
import Essays from './components/Essays'; 
import ReactMarkdown from 'react-markdown';

import ErrorBoundary from './components/ErrorBoundary';
import PaperScreenshot from './PaperScreenshotWebsite.png'; // Adjust the path if necessary
import EMWebsite from './219shots_so Mac Electomate home.png'; // Adjust the path if necessary
import ScreenshotDiplomatica from './ScreenshotDiplomatica.png'; // Adjust the path if necessary


import YouTubeEmbed from './components/YouTubeEmbed';
import './App.css';

const App = () => {
  const [openIndexEssays, setOpenIndexEssays] = useState(null);
  const [openIndexProjects, setOpenIndexProjects] = useState(null);
  const [openIndexProjectIdeas, setOpenIndexProjectIdeas] = useState(null);
  const [openIndexListVideosPhotos, setOpenIndexListVideosPhotos] = useState(null);
  const textareaRef = useRef(null); // Create a ref for the textarea

  const handleToggleEssays = (index) => {
    setOpenIndexEssays(openIndexEssays === index ? null : index);
  };

  const handleToggleProjects = (index) => {
    setOpenIndexProjects(openIndexProjects === index ? null : index);
  };

  const handleToggleProjectIdeas = (index) => {
    setOpenIndexProjectIdeas(openIndexProjectIdeas === index ? null : index);
  };

  const handleToggleListVideosPhotos = (index) => {
    setOpenIndexListVideosPhotos(openIndexListVideosPhotos === index ? null : index);
  };

  const ListVideosPhotos = [
    {
      id: "pQe3QYj68wg",
      title: "German Zero",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="pQe3QYj68wg" playing={openIndexListVideosPhotos === "pQe3QYj68wg"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "i6GETfyGDQc",
      title: "Weltenende ins Grüne",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="i6GETfyGDQc" playing={openIndexListVideosPhotos === "i6GETfyGDQc"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "-0SljoJPty0",
      title: "TUM Boring Release Movie",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="-0SljoJPty0" playing={openIndexListVideosPhotos === "-0SljoJPty0"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "0wTYMhIAYP0",
      title: "ZuMUN 2024 Aftermovie",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="0wTYMhIAYP0" playing={openIndexListVideosPhotos === "0wTYMhIAYP0"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "6OPsH8PK7xM",
      title: "Polylog - What P vs NP is actually about",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="6OPsH8PK7xM" playing={openIndexListVideosPhotos === "6OPsH8PK7xM"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "B3B9N5DnRjI",
      title: "FC Schalke 04 E Sports",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="B3B9N5DnRjI" playing={openIndexListVideosPhotos === "B3B9N5DnRjI"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "Euv2Y48VYlk",
      title: "Scenario Remix",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="Euv2Y48VYlk" playing={openIndexListVideosPhotos === "Euv2Y48VYlk"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "3rkv7k_kXNw",
      title: "SipTogether",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="3rkv7k_kXNw" playing={openIndexListVideosPhotos === "3rkv7k_kXNw"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "C-bK3T0GZio",
      title: "Phoenix Racing - DTM",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="C-bK3T0GZio" playing={openIndexListVideosPhotos === "C-bK3T0GZio"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "aIVCsj-U-CQ",
      title: "New Zealand Travels Trailer",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="aIVCsj-U-CQ" playing={openIndexListVideosPhotos === "aIVCsj-U-CQ"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "0EcpGuLZYes",
      title: "Music Video - Spasi Se Od Mene",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="0EcpGuLZYes" playing={openIndexListVideosPhotos === "0EcpGuLZYes"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "Sy9f1nO2REs",
      title: "24h Filmfestival",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="Sy9f1nO2REs" playing={openIndexListVideosPhotos === "Sy9f1nO2REs"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "sgvy1GfgaF8",
      title: "Mercedes G Class wagon chase",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="sgvy1GfgaF8" playing={openIndexListVideosPhotos === "sgvy1GfgaF8"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    },
    {
      id: "a7koSW9vg90",
      title: "BTS - Music Video Shoot",
      content: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <YouTubeEmbed videoId="a7koSW9vg90" playing={openIndexListVideosPhotos === "a7koSW9vg90"} />
        </div>
      ),
      year: "2024",
      ActiveYN: "(Active)",
    }
  ];
  



  


  const dropdownContentsEssays = [
    { id: 1, title: "How to Apologize", content: `
      ### The Range of Responses:

      - **Denial and Self-Victimization:**
        - Denial of reality.
        - Inverting situations to make you question your own perception.
        - Self-victimizing: mistakes happen while being a victim yourself, often unaware of your own wrongdoings.
        - Offering excuses.
        - Attacking back.

      - **Minimizing and Justifying:**
        - Making excuses, saying it's not that bad.
        - Claiming the right to their actions.

      - **Silence and Minimal Acknowledgment:**
        - Not saying anything.
        - Just saying "sorry."

      - **Deflecting Responsibility:**
        - Saying "sorry that you feel that way."
        - Apologizing for the other person's understanding of the situation.
        - Saying "sorry that I made you feel that way."

      - **Acknowledging and Taking Responsibility:**
        - Apologizing with full acknowledgment: "Sorry that this happened. It's my fault."
        - Expressing understanding: "I understand this made you feel like that. I guess it's normal that you react this way, and it's not acceptable what I have done."
        - Admitting unintentional harm and recognizing a part of you that acted knowingly.

      - **Understanding and Commitment to Change:**
        - Explaining how your mistake has affected the other person.
        - Asking if your understanding of the impact is correct.
        - Showing genuine validation by understanding and explaining the other's feelings.
        - Making a plan for future behavior to prevent recurrence and protect the other person.
        - Showing through actions rather than just words.

      - **Differentiating Understanding and Change:**
        - Saying you understand vs. explaining how you understand.
        - Saying you'll change or regret vs. actually changing or showing effort to change.
    `},
    { id: 2, title: "Kinds of Influence", content: "" },
    { id: 3, title: "Being in tune or hurting people", content: "" },
    { id: 4, title: "Meaning of Life and Meaning of Love", content: "" },
    { id: 5, title: "About Veganism and Alt Proteins", content: "" },
    { id: 6, title: "How to make alt protein cool", content: `
   
    ` },
    { id: 7, title: "The position of acknowledgement is a privileged one", content: "Content discussing the privileged position of acknowledgment." },
    { id: 8, title: "Vier feeder tafel: about value of altruism", content: "Content discussing four square graph and the value of altruism." },
    { id: 9, title: "About Sustainability Ethics", content: "Content discussing different ethical fundaments: Biocentirsm, Geocentrism, Anthropocentrism." },
    { id: 10, title: "Societal change aspects", content: "Patterns of my mum, psychological effects and phases of discovering something like this." },
    { id: 11, title: "Evidence based and self reflecting as best and only learning needed for a human? The expectation to my children", content: "Content discussing evidence-based and self-reflecting learning and expectations for children." }
  ];

  const dropdownContentsProjects = [
    { id: 1, title: "Diplomatica", content: (
        <div>
          Content for Dropdown 1...
          <img 
            src={ScreenshotDiplomatica} 
            alt="Diplomatica Website" 
            style={{ borderRadius: '15px', marginTop: '10px', width: '50%' }} 
          />
          <a href="https://diplomatica.ai/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginLeft: '5px' }}>
            <span>Check out the website here ➔</span>
          </a>
        </div>
      ), year: "2024", ActiveYN: "(Active)"},
    { id: 2, title: "Elect-O-Mate",         content:  (
    
    <div>
      Content missing still...
      <img 
        src={EMWebsite} 
        alt="EM Website" 
        style={{ borderRadius: '15px', marginTop: '10px', width: '50%' }} 
      />
      <a href="https://elect-o-mate.eu/" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', marginLeft: '5px' }}>
        <span>Check out the website here ➔</span>
      </a>
    </div>)
    
    , year: "2024", ActiveYN: "(Active)"},
    { id: 3, title: "World3 Model Optimization via Genetic Algorithms", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 4, title: "Circularity Criticality in the Input-Output Model ", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 5, title: "Paper: Congestions and Spectral Transitions in Time-Lagged Correlations of Motorway Traffic", 
      content: (
        <div>
          <a href={"https://www.sciencedirect.com/science/article/pii/S0378437124004618"} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
            <span>Read Paper </span> ➔
          </a>
          <img 
        src={PaperScreenshot} 
        alt="Screenshot of the Paper >>" 
        style={{ borderRadius: '15px', marginTop: '10px', width: '100%' }}  />        </div>
      ), 
      year: "2024", 
      ActiveYN: "(Active)" 
    },
    { id: 6, title: "Alt Protein Project", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 7, title: "Effective Altruism", content: " ", year: "2024", ActiveYN: "(Active)"},
    { id: 8, title: "AI Safety", content: " ", year: "2024", ActiveYN: ""},
    { id: 9, title: "ZuMUN Conference 2024", content: ` `, year: "2024", ActiveYN: ""  },
    { id: 10, title: "ETH Model United Nations", content: "Content discussing the privileged position of acknowledgment.", year: "2024", ActiveYN: "" },
    { id: 11, title: "TUM Carbon", content: "Content discussing Vier feeder tafel and the value of altruism.", year: "2024", ActiveYN: ""},
    { id: 12, title: "TUM Boring", content: "Content discussing different ethical fundaments: Biozentirsm, GeoZentrism, Anthropozentrism.", year: "2024", ActiveYN: ""},
    { id: 13, title: "German Zero", content: "Patterns of my mum, psychological effects and phases of discovering something like this.", year: "2024", ActiveYN: ""},
    { id: 14, title: "Volt", content: "Content discussing evidence-based and self-reflecting learning and expectations for children.", year: "2024", ActiveYN: "" }
  ];




  const dropdownContentsProjectIdeas = [
    { id: 1, title: "Life Logger", content: "Ask what have I done today, Journal.Ai: YouTube video summaries at the end of everyday summaries my day. Maybe facing pointing out the most important thing that I have done on this day, it might help me to find the important things and see what the difference is between myself and an AI.", year: "2024", ActiveYN: "(Active)" },
    { id: 2, title: "Platform to Search for Initiatives at Your Uni or Events", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 5, title: "Dating App via AI Matching", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 6, title: "Finance4.0 Startup", content: " ", year: "2024", ActiveYN: "(Active)" },
    { id: 7, title: "LinkedIn Scraper and Ask Questions in 2nd Circle", content: "Content discussing LinkedIn scraping and querying in the 2nd circle.", year: "2024", ActiveYN: "(Active)" },
    { id: 8, title: "IDEA: Connect the Diplomatica Simulation with The New Commons Game or MUN or YouTube comments", content: "Have a look ???too aufwöndig.", year: "2024", ActiveYN: "(Active)" }
  ];





  

  return (
    <ErrorBoundary>
    <div className="App bg-">
      <div className="pb-0 px-10 md:px-20">

       




        <div className="pb-20 mt-32 lg:mt-52">

        <div className="rounded-full overflow-hidden w-32 h-32 mb-10">
          <img src="/Portrait_Gabor.jpeg" className="w-full h-full object-cover" alt="Portrait of Gabor Hollbeck" />
        </div>


          <LandingPage />
        </div>

        <div className="pb-0 mt-0">
  <h1 className="text-sm mt-10 text-black md:text-lg lg:text-lg xl:text-lg text-left font-bold mb-4 md:mt-0">About Me: Gabor Hollbeck</h1>
  <p className="text-sm text-black md:text-lg lg:text-lg xl:text-lg text-left">
    M.Sc. Physics @ETH Zürich, 
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(1); // Open the dropdown for Diplomatica
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
     <span></span> Diplomatica
    </span>
    
    , <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(2); // Open the dropdown for Elect-O-Mate
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
      Elect-O-Mate <span></span>
    </span> 
    
    and <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexProjects(6); // Open the dropdown for Alt Protein Project
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
      Alt Protein Project 
    </span> 
    

    
    <span></span>  and <span></span>
    <span 
      onClick={() => {
        const section = document.getElementById('projects-section');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          setOpenIndexEssays(5); // Open the dropdown for Complex Social Systems
        }
      }} 
      style={{ cursor: 'pointer', color: 'orange', fontWeight: 'bold' }}
    >
       Complex Social Systems
    </span>
    
    . Passionate about AI4Good, Sustainability, Longtermism, Social Entrepreneurship, Mindfulness and Rationality.
  </p>
</div>

        <div className="flex flex-col items-start mt-10">
    {Array.from({ length: 30 }).map((_, index) => (
      <div key={index} className={`transform transition-transform duration-300 rotate-90`}>
        <svg
          className="w-5 h-5 text-gray-600 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    ))}
</div>

        <div className="pb-0 mt-8">
        <div className="pb-0 mt-0">
            <h1 className="text-4xl font-bold mb-10">Essays</h1>
        </div>


          <Essays />
        </div>

        




{/* 
        <div className="pb-1 mt-0">
          <h1 className="text-lg font-bold mb-4">Essays</h1>
        </div>

        <div>
          {dropdownContentsEssays.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexEssays === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexEssays === id}
                toggleDropdown={() => handleToggleEssays(id)}
              >

            <div className="markdown-content">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>

            </DropdownTextBox>
            </div>
          ))}
        </div> */}







<div id="projects-section">
        <div className="pb-1 mt-2">
          <h1 className="text-4xl font-bold mb-5">Projects / Publications</h1>
          <h1 className="text-base mb-8">Just a list of in what, when, how, why I have been involved + what learnings I had</h1>
        
        </div>

        
          {dropdownContentsProjects.map(({ id, title, content, year, ActiveYN }) => (
            <div key={id} className={`${openIndexProjects === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={`${title}  `}
                activeYN = {`${ActiveYN}`}  
                isOpen={openIndexProjects === id}
                toggleDropdown={() => handleToggleProjects(id)}
              >
                {content}
                
              </DropdownTextBox>
            </div>
          ))}
        </div>





        <div className="pb-1 mt-10 ">
          <h1 className="text-4xl font-bold mb-5">Project Ideas <span className="text-sm">(but no time for me to pursue them)</span></h1>
          <h1 className="text-base mb-8">I want to provide a list of Ideas for Projectds, Startups or any endeavours, that I think have some value for the world or to yourself. I dont have enough time to make them happen, so feel free to be inspired and ask me if you want to have more insights or some supervision</h1>
        </div>

        <div>
          {dropdownContentsProjectIdeas.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexProjectIdeas === id ? 'mb-6 mt-2' : 'mb-0'}`}>
              <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexProjectIdeas === id}
                toggleDropdown={() => handleToggleProjectIdeas(id)}
              >
                {content}
              </DropdownTextBox>
            </div>
          ))}
        </div>


















        <div className="pb-1 mt-10">
          <h1 className="text-4xl font-bold  mb-5">Photo- and Videography</h1>
          <h1 className="text-base mb-8">Here is a short list of the my "professional" work in this field</h1>
        </div>

        {/* <div className="flex flex-wrap justify-center mb-6">
          {ListVideosPhotos.map(({ id }) => (
            <div key={id} className="m-2">
              <a href={`https://www.youtube.com/watch?v=${id}`} target="_blank" rel="noopener noreferrer">
                <YouTubeEmbed videoId={id} playing={false} />
              </a>
              <img src={`https://img.youtube.com/vi/${id}/0.jpg`} alt={`Thumbnail for video ${id}`} className="mt-2" />
            </div>
          ))}
        </div> */}
{/* 
<div className="video-grid mb-20">
  {ListVideosPhotos.map(({ id, title }) => (
    <div key={id} className="video-item">
      <YouTubeEmbed videoId={id} playing={false} />
      <p>{title}</p>
    </div>
  ))}
</div> */}


      


        <div>
          {ListVideosPhotos.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexListVideosPhotos === id ? 'mb-6 mt-2' : 'mb-0'}`}>
            <DropdownTextBox
              title={` ${title}`}
              isOpen={openIndexListVideosPhotos === id}
              toggleDropdown={() => handleToggleListVideosPhotos(id)}
            >
              {content}
            </DropdownTextBox>
          </div>
          ))}
        </div>










      </div>










      <div className=" mt-10  ">
        <div className="flex">
          {/* All your content here */}
        </div>
        <Footer />
      </div>
    </div>
        </ErrorBoundary>
  );
}

export default App;
