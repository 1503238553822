import React, { useEffect, useState, useRef } from 'react';
import '../index.css'; // Adjust the path as necessary
import DropdownTextBox from './DropdownTextBox';

import ReactMarkdown from 'react-markdown';
const Essays = () => {




const [openIndexEssays, setOpenIndexEssays] = useState(null);
const textareaRef = useRef(null); // Create a ref for the textarea
const handleToggleEssays = (index) => {
setOpenIndexEssays(openIndexEssays === index ? null : index);

  };

const ContentHowToApologize = () => (
    <div>

        <p>


        - <strong>-3 Denial and Self-Victimization, Counter accusation, gaslighting</strong><br />
- <strong>-2 Minimizing and Justifying</strong><br />
- <strong>-1 Apologizing but Deflecting Responsibility</strong><br />
- <strong>0 Silence and Minimal Acknowledgment:</strong><br />
- <strong>1 Acknowledging and Taking Responsibility</strong><br />
- <strong>2 Understanding, Explaining how your mistake has affected the other person.</strong><br />
- <strong>3 Commitment to Change:</strong><br /><br />

<strong>So step by step:</strong><br /><br />

1. Calm down, ask yourself how you could be wrong in your immediate emotional reaction after being confronted with that you hurt someone or made a mistake.<br />
<br />
2. Apologize and say you didn't intend.<br /> <br />
3. Examine how and why it happened, explain to the other person without excusing yourself, also say “this is not an excuse” but also check if it is.<br /> <br />
4. Examine how the other person must have felt like and explain it to them, ask if that's correct how you see it.<br /><br />
5. Imagine a situation in which you could contradict with what you are and how you are now. Don't step back from your apologies. At the same time, don't hold back objections and parts of the mistakes that you feel you need validation for, cause those, if rightful, will come up at other times and will hurt your conversant. Stay consistent with the settlement you find with the other party.<br /><br />
6. Imagine how to avoid the mistake the next time you face such a situation.<br /><br />
7. Work on damage control constantly (some part of the solution after a mistake or damage is to acknowledge and then to reinforce the apology and understanding through communication and action).<br /><br />
8. Actually commit to behave and think differently next time.<br /><br />
9. Remember you might forgive through understanding quite quickly, but you won't be able to forget timely. Understanding will only transform the anger into pain.<br /><br />




            <strong>The Range of Responses with examples:</strong><br /><br />
            
            - <strong>Denial and Self-Victimization:</strong><br />
                - Denial of reality.<br />
                - Inverting situations to make you question your own perception.<br />
                - Self-victimizing: mistakes happen while being a victim yourself, often unaware of your own wrongdoings.<br />
                - Offering excuses.<br />
                - Attacking back.<br /><br />

            - <strong>Minimizing and Justifying:</strong><br />
                - Making excuses, saying it's not that bad.<br />
                - Claiming the right to their actions.<br /><br />

            - <strong>Silence and Minimal Acknowledgment:</strong><br />
                - Not saying anything.<br />
                - Just saying "sorry."<br /><br />

            - <strong>Deflecting Responsibility:</strong><br />
                - Saying "sorry that you feel that way."<br />
                - Apologizing for the other person's understanding of the situation.<br />
                - Saying "sorry that I made you feel that way."<br /><br />

            - <strong>Acknowledging and Taking Responsibility:</strong><br />
                - Apologizing with full acknowledgment: "Sorry that this happened. It's my fault."<br />
                - Expressing understanding: "I understand this made you feel like that. I guess it’s normal that you react this way, and it’s not acceptable what I have done."<br />
                - Admitting unintentional harm and recognizing a part of you that acted knowingly.<br /><br />

            - <strong>Understanding and Commitment to Change:</strong><br />
                - Explaining how your mistake has affected the other person.<br />
                - Asking if your understanding of the impact is correct.<br />
                - Showing genuine validation by understanding and explaining the other's feelings.<br />
        </p>
    </div>
);

const ContentVeganism = () => (
    <div>
        <p>
            <strong>1.1. Veganism: Climate Change, Animal Cruelty, Antibiotics Resistance, Food Security, Health</strong><br /><br />

            <strong>Problem</strong><br />
            As I believe that all the problems and risks that come with modern conventional animal farming are well known to our group, I initially decided not to elaborate on this topic and rather put my efforts into the solutions and how to get there. However, I figured that I might show this document to people who are not informed or convinced of the reasons for veganism. I reconsidered and will just list some facts from the top of my head. I fact-checked all the points below, but there is always more to say and to be contextualized, so I ask the reader to google the rest.<br /><br />

            <strong>Feel free to skip - Reasons to go vegan:</strong><br />
            - Land use: ~80% of agricultural land globally is used for livestock.<br />
            - Food security: Low caloric efficiency (e.g. chicken 1:9 or beef 1:25) → you could feed 8 more people with the crops that you didn’t feed to the chicken.<br />
            - Deforestation, CO2 and methane emissions, Water footprint.<br />
            - Antibiotics: 80% of all antibiotics produced are used in animal farming → Pharma lobby backs livestock industry, 3 out of 4 emerging infectious diseases in humans are zoonotic. If antibiotic resistance is reached, modern medicine collapses, throwing us back into medieval times.<br />
            - Ethics: These animals have been shown to be capable of experiencing physical and emotional suffering. Yet, they are often confined in cages that are too small and overcrowded, living in their own feces, subjected to mal-slaughtering, repeatedly impregnated, and forcibly separated from their offspring. Their beaks and tails are cut off, and they are bred so rapidly that their bones break under the weight of their muscles. These experiences are perceived by them in ways not vastly different from how we humans would perceive such suffering.<br />
            - Health: Heart disease, artery clogging, diabetes, cancer risk. WHO classifies red meat as a carcinogen type 1, and white meat as class 2. This is the second big cash cow of the pharma industry, as the insurance expenses society covers for the health consequences of animal farming are immense.<br /><br />

            <strong>Solution</strong><br />
            Veganism is without a doubt one of the most influential measures someone can take on an individual level to act against climate change, animal welfare, pandemics & antibiotic resistance, as well as food security & world hunger. I believe there are many things one can do on an individual as well as systemic level to get there.<br /><br />

            To tackle these problems, it’s important to understand why such objectively harmful practices and habits are still existent today, and what led us into this situation. In short, my findings about this are a combination of the liberal market order, propaganda, and lack of consideration of "scope 2 and 3" consequences, to adapt wording from the GHG protocol, which allowed the emergence of such a complex anchoring of this industry.<br /><br />

            Furthermore, I recently found a great characterization of the psychology of eating animals:<br /><br />

            Following a summary of <em>The Meat Paradox</em> I quote from a Twitter user:<br /><br />

            <em>"Why are meat eaters & vegetarians often angry with vegans? Cognitive dissonance (c.d.) is a strategy used by the brain to ignore a conflict. In order to justify one’s own animal consumption and love of animals, certain 'farmed' animals are denied mental abilities and the ability to suffer (speciesism). Another variant of the c.d.: talking up one’s own behavior. 'I only eat a little meat', 'buy from the farmer', 'trusted butcher', etc. If meat eaters and vegetarians are now confronted with the vegan lifestyle, their c.d. no longer works properly. The study <em>The Psychology of Eating Animals</em> shows that they feel condemned by the mere encounter with vegans because their own contradiction is revealed. Now, since the c.d. of meat eaters & vegetarians does not make the vegan person disappear and cannot offer a solution to repression, it leads to stress & anger. These negative feelings are projected onto the vegan person because they are the trigger for the inner conflict. As a result, meat eaters are often angry with vegans without them having done anything wrong. The anger is then often unloaded on vegans in varying degrees of intensity."</em><br /><br />

            I now realize that my text might be a bit too long to go in-depth on everything. So here are the direct measures to take.<br /><br />

            1. Cut subsidies for animal products.<br />
            2. Enhance working conditions and rights for workers in such factories (specifically in Germany, where conditions are known to be very bad and below standard).<br />
            3. Add "hidden costs" to the product’s price. I would suggest introducing these financial changes gradually over 5-10 years.<br />
            4. Create labels on packages similar to those on cigarette packs.<br />
            5. At the same time, increase subsidies for vegan alternatives.<br /><br />

            I believe that organic, low-processed vegan food is the ultimate long-term nutrition source humanity should strive for. Nonetheless, in the short and medium term, alternative proteins are the only way to go. This is because forecasts show that meat consumption is far from declining. The global demand for meat is expected to double by 2050 due to the rising interest in developing countries outweighing the decline in some Western European countries.<br /><br />

            With alternative proteins, once similar or better in taste and equal or lower in price, we’d have an efficient, sustainable, reliable food source that also offers the next big tech industry, which could be used to convince investors to invest in it rather than oil and armaments. There is much more to say about alternative proteins, but I’ll leave that for another time.<br /><br />

            In addition to these measures, I would include nutrition education and everything related to it into the education system, as well as prohibit advertisements that proclaim the happiness of animals.<br /><br />

            <strong>Key Points:</strong><br />
            - Investment into alternative proteins.<br />
            - Cutting subsidies for animal products.<br />
            - Stopping propaganda.<br />
        </p>
    </div>
);


const PoemSolang = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Solang</strong><br />
            <em>an mich und die da draußen</em><br /><br />

            Solang mein Glück deinem Schmerz die Weichen stellt,<br />
            Solang mein Schmerz dein Schicksal hellt,<br />
            Solang mein Schicksal deinem Schmerz verfällt,<br />
            Solang mein Schmerz deinem Glück die Weichen stellt,<br />
            Ist mir alles egal<br /><br />

            Auch wenn Glück dem Schmerz den Rücken kehrt<br />
            über meinen wirst du nie belehrt<br />
            doch wär ein Danke schön<br />
            Nein, noch kein Dankeschön<br />
            Der Schmerz - noch in der Reife, Das Glück noch in der Weite<br />
            Und so warte ich und stelle in der Stille deine Weiche<br /><br />

            Wie wäre es wenn ich mich nicht im Schatten fand<br />
            Wenn die da draußen diesem folgten im Verstand,<br />
            Wenn nicht mir, dann denen widm’ich dies Gedicht<br />
            Warum das Glück mit Stille treibt und sie nicht bricht<br />
            Ist zu verstehen mein Herzens halber Willen<br />
            Der Rest ist dieses Wissen in Tat zu bringen<br /><br />

            Ich habe Glück, ich breche Stille, ich finde Helle,<br />
            Bald ich meine Worte (nie) wieder in stille stelle,<br />
            So ist mein später’s ich,<br />
            kein Werdenichts<br />
        </p>
    </div>
);

const PoemKurzweiligLangweilig = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Kurzweilig Langweilig</strong><br /><br />

            Kurzweilig schien mein Herz langweilig,<br />
            Außen Rau, Mitte flüchtig, innen Seidig<br /><br />

            Es war gerade entschlossen verschlossen,<br />
            plötzlich so langsam war es zerbrochen.<br />
            Und wieder <br />
            Und wieder <br />
            Und wieder ein letztes Mal <br />
            Und wieder <br />
            Das war mein Jahr und wir sind beide Verlierer<br /><br />

            Gehört hat es Lust<br />
            Gefühlt hat es Frust<br />
            Geglaubt hat es schlicht<br />
            Gesehen hat es nicht<br />
            Wahrlich war alles gemischt<br />
            Braucht es dies auf lange Sicht?<br /><br />
            <br /><br />
            English Version <br /><br />
            <strong style={{ fontSize: '1.5em' }}>Poem - Ephemeral Dullness</strong><br /><br />


            My heart seemed transient, yet dreary,<br />
            Outside rough, midst fleeting, inside silky.<br /><br />

            It was openly closed then tightly chosen,<br />
            Suddenly so slow, it was broken<br /><br />

            It heard desire,<br />
            Felt the fire of ire,<br />
            Believed with ease,<br />
            Saw none of these.<br />
            Truly, all was a mixed plight,<br />
            Does it need this, in the long night?<br /><br />
        </p>
    </div>
);

const PoemGefangen = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Gedicht - Gefangen</strong><br /><br />

            Mal wieder entzückt sich Unrecht von meiner Würde,<br />
            Verschleiernd umgibt mich das Unrecht an meiner Liebe,<br />
            Darum verlangt es mich, als ich nichts spürte,<br />
            Doch wie lang, bis ich nicht mehr weiter spiele,<br /><br />

            Gefangen zwischen meinem Schmerz und ihrer Qual,<br />
            So muss ich beistehen nur einer Übelkeit,<br />
            Die meiner Liebe ist meine Wahl,<br />
            Doch wie lang, bis mein Unrecht Ihres übersteigt<br />
            Nie<br /><br />
        </p>
    </div>
);

const PoemDeepStitches = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Deep Stitches</strong><br /><br />

            Hang in there - to all in pain<br />
            Greetings of the never knowing<br />
            I hear me saying  - you won’t go insane<br />
            From the wasted life - I am sowing<br /><br />

            Who am I to - continue stitching<br />
            Just to open your life red seam<br />
            To not see you - as my sibling<br />
            Fight for your life to redeem<br /><br />

            We’re not made to stitch on multilayers<br />
            First hidden and then uncovered<br />
            At the end - As if nothing matters<br />
            Cause we are safely buffered<br /><br />

            The threads of feelings, don't see the depths<br />
            The distant wrinkles nor the backside<br />
            Only mirrors instead of lenses<br />
            Will prevent a fatal landslide<br /><br />

            How is that<br />
            You don't hate<br />
            Humanity at its core<br />
            Is one thing only<br />
            The act around desires<br />
            Without morals<br /><br />

            A factory of sewing machines<br />
            Without caring about the mess on the inseam<br /><br />
        </p>
    </div>
);

const PoemBloomingsOfAFlowerGarden = () => (
    <div>
        <p>
            <strong style={{ fontSize: '1.5em' }}>Bloomings of a Flower Garden</strong><br /><br />
            Once there was a rose and a seedling<br />
            Tribe together in the wind of feelings<br />
            Love in both and both in love<br />
            For paintings of good faith<br />
            For lively lives and worthy worlds<br />
            For feelings fleeing from the jargon<br />
            For bloomings of a flower garden<br />
            Wishes of tribes to start mingling<br />
            Wishes to witness ended in bridling<br />
            With spading the roots by lying<br />
            With carvings to mark a striking<br />
            To grow into pleasing the gardener<br />
            Without protection to be a father<br />
            Seasons pass to anew the cycle<br />
            Settling into a life adjourned<br />
            Downing the soil to never returned<br />
            Still<br />
            Stretching roots into the void<br />
            Still<br />
            Gifting fruits to never return<br />
            Sprawling branches to reach a pardon<br />
            Painting with faith into a garden<br />
            Echoing ongoing spade stitches<br />
            But missing the promise to witness<br />
        </p>
    </div>
);


  const dropdownContentsEssays = [
    { id: 1, title: "How to Apologize (not empty)", content:  <ContentHowToApologize />},
  
    { id: 2, title: "Kinds of Influence", content: "" },
    { id: 3, title: "Being in tune or hurting people", content: "" },
    { id: 4, title: "Meaning of Life and Meaning of Love", content: "" },
    { id: 5, title: "About Veganism and Alt Proteins (not empty)", content: <ContentVeganism /> },
    { id: 6, title: "How to make alt protein cool", content: `
   
    ` },
    { id: 7, title: "Previledge of Aknowledgement", content: "Content discussing the privileged position of acknowledgment. The position of acknowledgement is a privileged one" },
    { id: 8, title: "About value of altruism", content: "Content discussing four square graph and the value of altruism." },
    { id: 9, title: "About Sustainability Ethics", content: "Content discussing different ethical fundaments: Biocentirsm, Geocentrism, Anthropocentrism." },
    { id: 10, title: "Societal change Psychology", content: "Patterns of my mum, psychological effects and phases of discovering something like this." },
    { id: 11, title: "My two expectations to my children ", content: "Content: Evidence based and self reflecting as best and only learning needed for a human? The expectation to my children. discussing evidence-based and self-reflecting learning and expectations for children." },
    { id: 12, title: "Gedicht - Solang (not empty)", content: <PoemSolang/> },
    { id: 13, title: "Poem - Deep Stitches (not empty)", content: <PoemDeepStitches/> },
    { id: 14, title: "Gedicht - Gefangen (not empty)", content: <PoemGefangen/> },
    { id: 15, title: "Poem - Ephemeral Dullness (not empty)", content: <PoemKurzweiligLangweilig/> },
{ id: 16, title: "Poem - Bloomings of a Flower Garden (not empty)", content: <PoemBloomingsOfAFlowerGarden /> }

];




  return (
    


  <div className="">
      <div className="pb-10 px-0">

 
        <div>
            {dropdownContentsEssays.map(({ id, title, content }) => (
            <div key={id} className={`${openIndexEssays === id ? 'mb-6 mt-2' : 'mb-0'}`}>
                <DropdownTextBox
                title={` ${title}`}
                isOpen={openIndexEssays === id}
                toggleDropdown={() => handleToggleEssays(id)}
                >
                
            <div className="markdown-content text-[10px] md:text-sm">
               {content}
            </div>

            </DropdownTextBox>
            </div>
            ))}
        </div>

    </div>
    </div>











  );
};

export default Essays;
