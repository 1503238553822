// import React, { useRef, useState, useEffect } from 'react';
// import YouTube from 'react-youtube';
// import './YouTubeEmbed.css'; // Import the CSS file


// const YouTubeEmbed = ({ videoId, playing }) => {
//     const playerRef = useRef(null);
//     const divRef = useRef(null);
//     const [height, setHeight] = useState('0px');

//     useEffect(() => {
//         const updateHeight = () => {
//             if (divRef.current) {
//                 const width = divRef.current.offsetWidth;
//                 setHeight(`${width * 0.564}px`); // Set height equal to 0.564 of the current width
//             }
//         };

//         window.addEventListener('resize', updateHeight);
//         updateHeight(); // Initial update

//         return () => window.removeEventListener('resize', updateHeight);
//     }, []);

//     const isFirstRender = useRef(true);

//     useEffect(() => {
//         if (isFirstRender.current) {
//             isFirstRender.current = false; // Mark first render complete
//             return; // Skip the effect on initial render
//         }
    
//         if (playerRef.current) {
//             if (playing) {
//                 playerRef.current.internalPlayer.pauseVideo();
//             } else {
//                 playerRef.current.internalPlayer.pauseVideo();
//             }
//         }
//     }, [playing]); // Dependency array to re-run the effect when 'playing' changes

//     const opts = {
//         height: height,
//         width: '100%',
//         playerVars: {
//             autoplay: 0,
//         },
//     };

//     const onError = (event) => {
//         console.error('YouTube video error:', event);
//     };

//     const wrapperStyle = {
//         borderRadius: '20px',
//         overflow: 'hidden',
//         width: '100%',
//         height: height,
//     };

//     return (
//         <div ref={divRef} style={wrapperStyle}>
//             <div className="youtube-embed-wrapper">
//                 <YouTube ref={playerRef} videoId={videoId} opts={opts} onError={onError} iframeClassName="youtube-iframe" />
//             </div>
//         </div>
//     );
// };

// export default YouTubeEmbed;


import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube'; // Import the YouTube specific ReactPlayer
import './YouTubeEmbed.css'; // Import the CSS file

const YouTubeEmbed = ({ videoId }) => { // Removed 'playing' from the props
    const playerRef = useRef(null);
    const divRef = useRef(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        const updateHeight = () => {
            if (divRef.current) {
                const width = divRef.current.offsetWidth;
                setHeight(`${width * 0.564}px`); // Set height equal to 0.564 of the current width
            }
        };

        window.addEventListener('resize', updateHeight);
        updateHeight(); // Initial update

        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const onError = (event) => {
        console.error('YouTube video error:', event);
    };

    const wrapperStyle = {
        borderRadius: '20px',
        overflow: 'hidden',
        width: '100%',
        height: height,
    };

    return (
        <div ref={divRef} style={wrapperStyle}>
            <ReactPlayer
                ref={playerRef}
                url={`https://www.youtube.com/watch?v=${videoId}`} // Construct the URL using the videoId
                playing={false} // Ensure the video does not autoplay
                width='100%'
                height={height}
                onError={onError}
                config={{
                    youtube: {
                        playerVars: { autoplay: 0 }, // Ensure autoplay is set to 0
                    },
                }}
            />
        </div>
    );
};

export default YouTubeEmbed;
